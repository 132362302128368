import { render, staticRenderFns } from "./NewHistoryComponent.vue?vue&type=template&id=64d8243f&scoped=true&"
import script from "./NewHistoryComponent.vue?vue&type=script&lang=js&"
export * from "./NewHistoryComponent.vue?vue&type=script&lang=js&"
import style0 from "./NewHistoryComponent.vue?vue&type=style&index=0&id=64d8243f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d8243f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VChip,VDivider,VIcon,VListItem})
